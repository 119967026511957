#header {
  background-color: var(--color-blue-light);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}
#header .row {
  align-items: center;
}
#header .site-logo {
  padding: 0 calc(100vw * 35/1900);
}
#header .site-logo a {
  display: inline-block;
}
#header .site-logo img {
  width: auto;
  height: calc(100vw * 72/1900);
}
#header .site-nav {
  display: flex;
  justify-content: right;
}
#header .site-nav nav .desktop-menu-list {
  display: flex;
  align-items: center;
  margin: 0;
}
#header .site-nav nav .desktop-menu-list li {
  list-style-type: none;
}
#header .site-nav nav .desktop-menu-list li a {
  display: inline-block;
  padding: calc(100vw * 17/1900) calc(100vw * 15/1900);
  /* min-height: calc(100vw * 90/1900); */
  text-align: center;
  color: #ffffff;
  position: relative;
  transition: var(--transition);
}
#header .site-nav nav .desktop-menu-list li a .menu-icon img {
  height: calc(100vw * 40/1900);
  width: auto;
  margin-bottom: calc(100vw * 15/1900);
}
#header .site-nav nav .desktop-menu-list li a .menu-text {
	font-family:'Aktiv Grotesk';
  font-size: calc(100vw * 16/1900);
  font-weight: 600;
  line-height: calc(100vw * 20/1900);
  min-height: calc(100vw * 40/1900);
}
#header .site-nav nav .desktop-menu-list li a:not(.call-btn) {
  border-left: calc(100vw * 1/1900) solid #ffffff;
  width: calc(100vw * 100/1900);
}
#header .site-nav nav .desktop-menu-list li a:not(.call-btn):hover {
  background-color: var(--color-blue-dark);
  transition: var(--transition);
}
#header .site-nav nav .desktop-menu-list li:first-child a:not(.call-btn) {
  border-left: none;
}
#header .site-nav nav .desktop-menu-list li a.call-btn {
  background: var(--color-gradient);
  padding: calc(100vw * 48/1900) calc(100vw * 35/1900);  
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
#header .site-nav nav .desktop-menu-list li a.call-btn .menu-text {
  min-height: auto;
}
#header .site-nav nav .desktop-menu-list li a.call-btn .menu-icon,
#header .site-nav nav .mobile-menu a.call-btn .menu-icon {
  /* display: inline-block; */
  width: calc(100vw * 50/1900);
  height: calc(100vw * 50/1900);
  border-radius: 50%;
  line-height: calc(100vw * 50/1900);
  text-align: center;
  left: calc(0px - ((100vw * 50/1900)/2));
  top: calc(50% - ((100vw * 50/1900)/2));
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-white);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
}
#header .site-nav nav .desktop-menu-list li a.call-btn .menu-icon::after,
#header .site-nav nav .mobile-menu a.call-btn .menu-icon::after {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 100%;
  background: var(--color-white);
  border-radius: 100%;
  z-index: -1;

  animation-name: ripple;
  animation-duration: 1.5s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
}
/* #header .site-nav nav .desktop-menu-list li a.call-btn:hover .menu-icon::after {
  animation-name: ripple;
  animation-duration: 1.5s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
} */
#header .site-nav nav .desktop-menu-list li a.call-btn .menu-text {
  font-size: calc(100vw * 25/1900);
  font-weight: 600;
  line-height: calc(100vw * 18/1900); 
  flex: 1;
}
#header .site-nav nav .desktop-menu-list li a.call-btn .menu-text-sub {
  font-size: calc(100vw * 12/1900);
  font-weight: 400;
  line-height: calc(100vw * 18/1900);    
  flex: 1;
  align-items: end;
  display: flex;
}
#header .site-nav nav .desktop-menu-list li a.call-btn-adjacent {
  padding: calc(100vw * 17/1900) calc(100vw * 35/1900);
}
#header .site-nav nav .desktop-menu-list li span {
  display: block;
}

@media only screen and (max-width: 1024px) {
  #header .site-logo {
    padding: 0 calc(100vw * 35/1024);
  }
  #header .site-logo img {
    height: calc(100vw * 50/1024);
  }
  #header .site-nav nav .desktop-menu-list li a {
    padding: calc(100vw * 10/1024) calc(100vw * 10/1024);
  }
  #header .site-nav nav .desktop-menu-list li a .menu-icon img {
    height: calc(100vw * 30/1024);
    margin-bottom: calc(100vw * 15/1024);
  }
  #header .site-nav nav .desktop-menu-list li a .menu-text {
    font-size: calc(100vw * 12/1024);
    line-height: calc(100vw * 16/1024);
    min-height: calc(100vw * 32/1024);
  }
  #header .site-nav nav .desktop-menu-list li a:not(.call-btn) {
    border-left: calc(100vw * 1/1024) solid #ffffff;
    max-width: calc(100vw * 80/1024);
  }
  #header .site-nav nav .desktop-menu-list li a.call-btn {
    padding: calc(100vw * 32/1024) calc(100vw * 25/1024);  
  }
  #header .site-nav nav .desktop-menu-list li a.call-btn .menu-icon {
    width: calc(100vw * 40/1024);
    height: calc(100vw * 40/1024);
    line-height: calc(100vw * 40/1024);
    left: calc(0px - ((100vw * 40/1024)/2));
    top: calc(50% - ((100vw * 40/1024)/2));
  }
  #header .site-nav nav .desktop-menu-list li a.call-btn .menu-text {
    font-size: calc(100vw * 20/1024);
    line-height: calc(100vw * 20/1024); 
  }
  #header .site-nav nav .desktop-menu-list li a.call-btn .menu-text-sub {
    font-size: calc(100vw * 10/1024);
    line-height: calc(100vw * 16/1024);    
  }
  #header .site-nav nav .desktop-menu-list li a.call-btn-adjacent {
    padding: calc(100vw * 10/1024) calc(100vw * 35/1024);
  }
}

@media only screen and (max-width: 599px) {
  #header .row {
    flex-direction: row;
  }
  #header .site-logo {
    padding: calc(100vw * 15/390) calc(100vw * 15/390);
  }
  #header .site-logo img {
    height: calc(100vw * 29/390);
  }
  #header .site-nav nav .mobile-menu {
    position: relative;
  }
  #header .site-nav nav .mobile-menu a.call-btn {
    padding: calc(100vw * 15/390) calc(100vw * 15/390);  
  }
  #header .site-nav nav .mobile-menu a.call-btn .menu-icon {
    width: calc(100vw * 33/390);
    height: calc(100vw * 33/390);
    line-height: calc(100vw * 33/390);
    left: calc(0px - ((100vw * 66/390)/2)) !important;
    top: calc(50% - ((100vw * 33/390)/2));
    font-size: calc(100vw * 12/390);
  }
  #header .site-nav nav .mobile-menu a.call-btn .menu-text {
    font-weight: 600;
    font-size: calc(100vw * 16/390);
    line-height: calc(100vw * 20/390); 
    color: var(--color-white);
  }
  #header .site-nav nav .mobile-menu .mobile-menu-toggle {
    border: none;
    background: transparent;
    margin-right: calc(100vw * 15/390);
    width: calc(100vw * 25/390);
    text-align: center;
    transition: var(--transition);
  }
  #header .site-nav nav .mobile-menu .mobile-menu-toggle svg {
    font-size: calc(100vw * 16/390);
    line-height: calc(100vw * 20/390); 
    color: var(--color-white);
  }
  #header .site-nav nav .mobile-menu-list-holder {
    position: absolute;
    right: 0;
    top: calc(100vw * 40/390);
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 154, 191,0.9);
    transition: left 0.3s ease;
    padding: calc(100vw * 25/390) calc(100vw * 15/390);
  }
  #header .site-nav nav .mobile-menu-list li {
    list-style-type: none;
  }
  #header .site-nav nav .mobile-menu-list li a {
    display: inline-flex;
    padding: calc(100vw * 10/390) calc(100vw * 15/390);
    margin-bottom: calc(100vw * 15/390);
    max-width: 100% !important;
    align-items: center;
  }  
  #header .site-nav nav .mobile-menu-list li a span {
    display: inline-block;
  }
  #header .site-nav nav .mobile-menu-list li a .menu-icon {
    margin-right: calc(100vw * 15/390);
  }
  #header .site-nav nav .mobile-menu-list li a .menu-icon img {
    width: calc(100vw * 35/390);
    height: auto;
    margin-bottom: 0;
  }
  #header .site-nav nav .mobile-menu-list li a .menu-text {
    font-size: calc(100vw * 16/390);
    font-weight: 600;
    line-height: calc(100vw * 32/390);
    min-height: calc(100vw * 32/390);
    color: var(--color-white);
  }
  #header .site-nav nav .mobile-menu-list li a:not(.call-btn) {
    border-left: calc(100vw * 3/390) solid #ffffff;
    max-width: calc(100vw * 80/390);
  }
}