.thank-you {
text-align: center;
}
.thank-you h1 {
    font-size: 26px;
    margin: 4px;
}
.section-contact {
    padding: calc(100vw * 50/1900) 0 calc(100vw * 30/1900);
  }
  .section-contact h2.title {
    font-weight: 600;
    font-size: calc(100vw * 66/1900);
    margin-bottom: calc(100vw * 14/1900);
    line-height: calc(100vw * 70/1900);
    text-align: center;
  }
  .section-contact p.subtitle {
    text-align: center;
    font-size: calc(100vw * 27/1900);
    font-weight: 400;
    line-height: normal;
  }
  /*
  .section-contact .row {
    gap: calc(100vw * 150/1900);
  }
  */
  .section-contact form {
    padding: calc(100vw * 110/1900) calc(100vw * 150/1900);
  }
  .section-contact .form-wrapper {
    grid-column-gap: 5%;
    grid-row-gap: 45px;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-template-rows: repeat(auto-fill,auto);
    width: 100%;
  }
  .section-contact .form-wrapper .field {
    grid-column: span 6;
  }
  .section-contact .form-wrapper .field label.field-label {
    font-size: calc(8.8px + (33.60 - 14.8) * ((100vw - 320px) / (2560 - 320)));
    font-family: 'Aktiv Grotesk';
    font-weight: 500;
  }
  input[type="text"],input[type="email"] {
    width: 100%;
    font-size: calc(8.8px + (33.60 - 14.8) * ((100vw - 320px) / (2560 - 320)));
    border: none;
    height: calc(100vw * 82/1900);
    border-bottom: 1px solid black;
  }
  .section-contact .field select {
    padding: calc(100vw * 15/1900) calc(100vw * 317/1900) calc(100vw * 15/1900) 0;
    margin: calc(100vw * 25/1900) 0 0 0;
    font-size: calc(100vw * 24/1900);
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
  }
  .section-contact textarea {
    min-width: calc(100vw * 1149/1900);
    max-width: calc(100vw * 1149/1900);
    font-size: calc(8.8px + (33.60 - 14.8) * ((100vw - 320px) / (2560 - 320)));
    border: none;
    height: calc(100vw * 82/1900);
    border: 1px solid black;
    margin: calc(100vw * 24/1900) 0px;
  }
  .section-contact .form-wrapper label.field-label {
    font-size: calc(8.8px + (33.60 - 14.8) * ((100vw - 320px) / (2560 - 320)));
    font-family: 'Aktiv Grotesk';
    font-weight: 500;
  }
  .section-contact form button,
  .section-contact form a {
    width: calc(100vw * 203/1900);
    height: calc(100vw * 63/1900);
    flex-shrink: 0;
    color: #000;
    font-size: calc(100vw * 18/1900);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: transparent;
    border-radius: calc(100vw * 30/1900);
    border: 1px solid #000;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
  }
  
  .section-contact form button:hover,
  .section-contact form a:hover {
    background-color: #39a5bf;
    color: #fff;
  }
  
  
  .section-contact form h4.title {
    font-size: calc(100vw * 30/1900);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.3px;
    text-align: center;
    margin: calc(100vw * 68/1900) 0;
  }
  .section-contact .button-holder {
    display: flex;
    justify-content: center;
    padding: 0 calc(100vw * 150/1900);;
  }
  .section-contact a.btn-cust {
    width: calc(100vw * 317/1900);
    height: calc(100vw * 46/1900);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .section-contact .btn-cust span {
    font-size: calc(100vw * 18/1900);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.18px;
  }
  .section-contact .btn-cust span img {
    height: calc(100vw * 24/1900);
    width: auto;
  }
  .section-contact .menu-icon{
    margin-right: calc(100vw * 15/1900);
  }
  .section-contact form h4.title.business-hours {
    margin-bottom: calc(100vw * 15/1900);
  }
  .section-contact .left .time {
    text-align: right;
  }
  .section-contact .right .time {
    text-align: left;
  }
  .section-contact .time {
    padding: 0 calc(100vw * 5/1900);
  }
  @media only screen and (max-width: 1024px) {

    .section-contact h2.title {
      font-size: calc(100vw * 66/1024);
      margin-bottom: calc(100vw * 34/1024);
      line-height: calc(100vw * 70/1024);
    } 

    .section-contact p.subtitle {
      font-size: calc(100vw * 24/1024);
    }

    .section-contact textarea {
      height: calc(100vw * 105/1024);
    }

    .section-contact form a {
      width: calc(100vw * 203/1024);
      height: calc(100vw * 63/1024);
      font-size: calc(100vw * 18/1024);
      border-radius: calc(100vw * 30/1024);
    }
    .section-contact form h4.title {
      font-size: calc(100vw * 30/1024);
      margin: calc(100vw * 68/1024) 0;
    }
    .section-contact .btn-cust span {
      font-size: calc(100vw * 18/1024);
    }
    .section-contact a.btn-cust {
      width: calc(100vw * 285/1024);
      height: calc(100vw * 46/1024);
    }
    .section-contact .button-holder {
      padding: 0 calc(100vw * 0/1024);
  }

  }
  @media only screen and (max-width: 599px ) {
    .section-contact .boxed {
      padding: calc(100vw * 55/390) calc(100vw * 35/390) calc(100vw * 15/390);
    }
    .section-contact .boxed {
      max-width: calc(100vw * 535/390);
    }
      .section-contact h2.title {
          font-weight: 600;
          font-size: calc(100vw * 32/390);
          line-height: calc(100vw * 37.44/390);
          margin-bottom: calc(100vw * 25/390);
          text-align: center;
          letter-spacing: -0.32px;
      }
      .section-contact p.subtitle {
        text-align: center;
        font-size: calc(100vw * 18/390);
        font-weight: 400;
        line-height: calc(100vw * 21.06/390);
      }
      .section-contact form {
        padding: calc(100vw * 60/390) calc(100vw * 0/390);
      }
      .section-contact .form-wrapper {
        grid-column-gap: 10%;
        grid-row-gap: 45px;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: repeat(auto-fill,auto);
        width: 100%;
      }
      .section-contact .form-wrapper .field label.field-label {
        font-size: calc(100vw * 16/390);
        font-weight: 500;
        width: 100%;
        display: block;
      }
      input[type="text"], input[type="email"] {
        height: calc(100vw * 55/390);
        font-size: calc(100vw * 18/390);
      }
      .section-contact textarea {
        max-width: 100%;
        width: 100%;
        font-size: calc(100vw * 18/390);
        border: none;
        height: calc(100vw * 210/390);
        border: 1px solid black;
        margin: calc(100vw * 24/390) 0;
      }
      .section-contact form a, 
      .section-contact form button {
        width: calc(100vw * 150/390);
        height: calc(100vw * 65/390);
        flex-shrink: 0;
        color: #000;
        font-size: calc(100vw * 16/390);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: transparent;
        border-radius: calc(100vw * 55/390);
        border: 1px solid #000;
    }
      .section-contact form h4.title {
        font-size: calc(100vw * 30/390);
        font-style: normal;
        font-weight: 700;
        line-height: calc(100vw * 35.1/390);
        letter-spacing: -0.3px;
        text-align: center;
        margin: calc(100vw * 68/390) 0 calc(100vw * 30/390);
    }
    .section-contact .button-holder {
      display: flex;
      justify-content: space-around;
      padding: 0 calc(100vw * 150/390);
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
    }
    .section-contact a.btn-cust {
      width: calc(100vw * 300/390)!important;
      height: calc(100vw * 45/390)!important;
      margin: calc(100vw * 12/390) 0 0;
    }

    .section-contact .field select {
      padding: calc(100vw * 18/390) calc(100vw * 143/390) calc(100vw * 18/390) 0;
      margin: calc(100vw * 25/390) 0 0 0;
      font-size: calc(100vw * 18/390);
    }
    .section-contact .btn-cust span {
      font-size: calc(100vw * 18/390);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.18px;
    }

    .section-contact .btn-cust span .step-inner {
      font-size: calc(100vw * 24/390);
      margin-right: calc(100vw * 5/390);
  }
    .section-contact .btn-cust span img {
      height: calc(100vw * 20/390);
      width: auto;
    }
    #section-contact > div > div > div > form > div:nth-child(6) {
      flex-direction: row;
    }
    .section-contact .time {
      padding: 0 calc(100vw * 5/390);
    }
}