/* Custom Font */
@font-face{
	font-family:'Aktiv Grotesk';
	font-weight:700;
	font-style:normal;
	src:url(./assets/fonts/aktivgroteskcorp/AktivGroteskCorp-Bold.ttf) format('truetype');
}
@font-face{
	font-family:'Aktiv Grotesk';
	font-weight:700;
	font-style:italic;
	src:url(./assets/fonts/aktivgroteskcorp/AktivGroteskCorp-BoldItalic.ttf) format('truetype');
}
@font-face{
	font-family:'Aktiv Grotesk';
	font-weight:400;
	font-style:italic;
	src:url(./assets/fonts/aktivgroteskcorp/AktivGroteskCorp-Italic.ttf) format('truetype');
}
@font-face{
	font-family:'Aktiv Grotesk';
	font-weight:300;
	font-style:normal;
	src:url(./assets/fonts/aktivgroteskcorp/AktivGroteskCorp-Light.ttf) format('truetype');
}
@font-face{
	font-family:'Aktiv Grotesk';
	font-weight:300;
	font-style:italic;
	src:url(./assets/fonts/aktivgroteskcorp/AktivGroteskCorp-LightItalic.ttf) format('truetype');
}
@font-face{
	font-family:'Aktiv Grotesk';
	font-weight:600;
	font-style:normal;
	src:url(./assets/fonts/aktivgroteskcorp/AktivGroteskCorp-Medium.ttf) format('truetype');
}
@font-face{
	font-family:'Aktiv Grotesk';
	font-weight:400;
	font-style:normal;
	src:url(./assets/fonts/aktivgroteskcorp/AktivGroteskCorp-Regular.ttf) format('truetype');
}