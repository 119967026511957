/* Globals */
:root {
	--font-family-default: 'Aktiv Grotesk';
  /* --font-family-accent: 'Marck Script', cursive; */

  --section-width-full: 100%;
  --section-width-wider: calc(100vw * 1830/1900);
  --section-width-wide: calc(100vw * 1600/1900);
  --section-width-boxed: calc(100vw * 1450/1900);

  --transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  --color-blue-dark: #004D71;
  --color-blue-light: #009ABF;
  --color-grey: #F5F5F5;
  --color-grey-dark: #c7c9c1;
  --color-dark: #151a02;
  --color-white: #ffffff;
	--color-transparent: rgba(0, 0, 0, 0);
  --color-gradient: linear-gradient(90deg, #004D71  0%, #009ABF 100%);
  --color-gradient-reverse: linear-gradient(90deg, #009ABF  0%, #004D71 100%);

  --shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
}
* {
	transition: var(--transition);
}
html {
	scroll-behavior: smooth;
}
html,
body {
  padding: 0;
  margin: 0;
	overflow-x: hidden;
}
html,
body,
section {
	background: white;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ol,
ul,
span {
  font-family: var(--font-family-default);
  line-height: 1;
  word-break: break-word;
	margin: 0;
}
p, 
a {
	font-weight: 300;
}
a,
a:hover  {
	text-decoration: none;
	color: var(--color-blue-light);
}
section,
.section {
	position: relative;
}
.background-holder {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
  background-position: center;
  background-size: cover;
	z-index: 0;
}
.container {
  padding: 0;
  margin: 0 auto;
  position: relative;
	z-index: 1;
}
.full {
  max-width: var(--section-width-full);
}
.wide {
  max-width: var(--section-width-wide);
}
.wider {
  max-width: var(--section-width-wider);
}
.boxed {
  max-width: var(--section-width-boxed);
}
.row {
	display: flex;
	width: 100%;
}
.col.col-5 {
	width: 5%;
}
.col.col-10 {
	width: 10%;
}
.col.col-15 {
	width: 15%;
}
.col.col-20 {
	width: 20%;
}
.col.col-25 {
	width: 25%;
}
.col.col-30 {
	width: 30%;
}
.col.col-33 {
	width: 33.33%;
}
.col.col-40 {
	width: 40%;
}
.col.col-45 {
	width: 45%;
}
.col.col-50 {
	width: 50%;
}
.col.col-55 {
	width: 55%;
}
.col.col-60 {
	width: 60%;
}
.col.col-70 {
	width: 70%;
}
.col.col-75 {
	width: 75%;
}
.col.col-80 {
	width: 80%;
}
.col.col-85 {
	width: 85%;
}
.col.col-90 {
	width: 90%;
}
.col.col-95 {
	width: 95%;
}
.col.col-100 {
	width: 100%;
}
.desktop {
	display: block;
}
.mobile {
	display: none;
}
.btn {	
	display: inline-block;
	font-weight: 400;
	font-size: calc(100vw * 18/1900);
	line-height: calc(100vw * 28/1900);
	text-transform: uppercase;
	padding: calc(100vw * 10/1900) calc(100vw * 35/1900);
	transition: var(--transition);
	position: relative;
}
.btn-primary {	
	color: var(--color-white);
	background-color: var(--color-blue-dark);
	border: calc(100vw * 1/1900) solid var(--color-blue-dark);
}
.btn-primary:hover {	
	color: var(--color-blue-dark);
	background-color: var(--color-transparent);
	transition: var(--transition);
}
.btn-secondary {	
	color: var(--color-white);
	background-color: var(--color-blue-light);
	border: calc(100vw * 1/1900) solid var(--color-blue-light);
}
.btn-secondary:hover {	
	color: var(--color-blue-light);
	background-color: var(--color-transparent);
	transition: var(--transition);
}
.accordion {
	margin: 2rem auto;
}
.accordion-item {
	margin: calc(100vw * 12/1900) 0;
}  
.accordion-title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
	background-color: #15A0C0;
}
.accordion-title:hover {
	background-color: #15A0C0;
}
.accordion-title,
.accordion-content {
	padding: 1rem;
}
.accordion-title{
	color: #FFF;
	font-size: calc(100vw * 27/1900);
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	font-family: 'Aktiv Grotesk';
}  
.accordion-content {
	background-color: #fff;
	border: 1px solid #15A0C0;
	font-family: 'Aktiv Grotesk';
	font-size: calc(100vw * 27/1900);
}
/* .section-11 .content-holder {
    text-align: center;
  }
.section-11 .content-holder h3.title{
	font-weight: 600;
	font-size: calc(100vw * 66/1900);
	line-height: calc(100vw * 70/1900);
	letter-spacing: -1%;
	margin-bottom: calc(100vw * 25/1900);
}
.section-11 .content-holder p.subtitle{
	font-weight: 400;
	font-size: calc(100vw * 28/1900);
	line-height: calc(100vw * 55/1900);
	color: #009ABF;
} */

/* .button-primary {
	color: white;
	font-size: calc(14.8px + (33.60 - 14.8) * ((100vw - 320px) / (2560 - 320)));
	border: 1px solid white;
	padding: 20px 55px;
	border-radius: 55px;
	display: inline-block;
}
.button-primary.dark {
	color: black;
	border-color: black;
}
.button-primary:hover {
	background: black;
	border: 1px solid black;
}
.button-primary.dark:hover {
	color: white;
}
.button-secondary {
	color: white;
	font-size: calc(14.8px + (33.60 - 14.8) * ((100vw - 320px) / (2560 - 320)));
	border-bottom: 1px solid white;
	display: inline-block;
}
.button-secondary.dark {
	color: black;
	border-color: black;
}
.button-secondary:hover {
	color: black;
	border-bottom: 1px solid black;
}
.button-accent {
	font-size: calc(11.8px + (19.12 - 11.8) * ((100vw - 320px) / (2560 - 320)));
	background: black;
	color: white;
	padding: 10px 155px;
	border-radius: 20px;
}
.button-accent.yellow {
	background: #FFD900;
	color: black;
}
.button-accent:hover {
	background: white;
	color: black;
}
.button-accent-hallow {
	min-width: 350px;
	display: inline-block;
	font-size: calc(11.8px + (19.12 - 11.8) * ((100vw - 320px) / (2560 - 320)));
	background: white;
	color: black;
	padding: 10px;
	border: 1px solid black;
	border-radius: 20px;
}
.button-accent-hallow:hover {
	background: #FFD900;
	border-color: #FFD900;
}*/

.multi-list-holder {
	max-width: calc(100vw * 1100/1900);
	margin: 0 auto;
}
.multi-list-item {
	display: inline-block;
	width: 25%;
	float: left;
}
.multi-list-item a,
.multi-list-item p {
	margin: calc(100vw * 5/1900);
	background: #F5F5F5;
	padding: calc(100vw * 10/1900) calc(100vw * 20/1900);
	display: flex;
	align-items: center;
	font-size: calc(100vw * 20/1900);
	font-weight: 400;
	line-height: calc(100vw * 24/1900);
	color: #151a02;
}
.multi-list-item a:hover {
	color: var(--color-blue-light);
}
/*.multi-list-item p:hover {
	background: #FFD900;
}*/
.multi-list-item a svg,
.multi-list-item p svg {
	font-size: calc(100vw * 15/1900);
	margin-right: calc(100vw * 20/1900);
}

/* .contact-form-holder {
	max-width: 1100px;
	margin: 75px auto 0;
}
.contact-form-holder .row {
	margin-bottom: 25px;
}
.contact-form-holder p {
	text-align: left;
	padding: 0 15px;
	margin-bottom: 15px;
}
.contact-form-holder p label,
.contact-form-holder .gform_wrapper.gravity-theme .gfield_label {
	font-size: calc(8.8px + (33.60 - 14.8) * ((100vw - 320px) / (2560 - 320)));
    font-family: 'Aktiv Grotesk';
    font-weight: 500;
}
.contact-form-holder .form-input,
.contact-form-holder .gform_wrapper.gravity-theme .gfield input, 
.contact-form-holder .gform_wrapper.gravity-theme .gfield select,
.contact-form-holder .gform_wrapper.gravity-theme .gfield textarea {
	width: 100%;
	font-size: calc(8.8px + (33.60 - 14.8) * ((100vw - 320px) / (2560 - 320)));
	border: none;
	border-bottom: 1px solid black;
}
.contact-form-holder textarea.form-input,
.contact-form-holder .gform_wrapper.gravity-theme .gfield textarea {
	border: 1px solid black;
}
.contact-form-holder .gform_wrapper.gravity-theme .gform_footer input {
    font-size: calc(14.8px + (33.60 - 14.8) * ((100vw - 320px) / (2560 - 320)));
    border: 1px solid black;
    padding: 20px 55px;
    border-radius: 55px;
    display: inline-block;
    background: white !important;
    color: black !important;
}
.contact-form-holder .gform_wrapper.gravity-theme .gform_footer input:hover {
	background: black !important;
    color: white !important;
} */

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
 }
  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
 }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
 }
 100% {
    transform: rotate(360deg);
 }
}

/* Scroll Bar */
/* width */
::-webkit-scrollbar {
	width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
	background: var(--color-grey);
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--color-blue-dark);
	border: 2px solid var(--color-grey);
	border-radius: 3px;
}

@media only screen and (max-width: 1024px) {
	:root {
		--section-width-wider: calc(100vw * 900/1024);
		--section-width-wide: calc(100vw * 850/1024);
		--section-width-boxed: calc(100vw * 800/1024);
	}
	.btn {	
		font-size: calc(100vw * 16/1024);
		line-height: calc(100vw * 24/1024);
		padding: calc(100vw * 10/1024) calc(100vw * 35/1024);
	}
	.btn-primary {	
		border: calc(100vw * 1/1024) solid var(--color-blue-dark);
	}
	.btn-secondary {	
		border: calc(100vw * 1/1024) solid var(--color-blue-light);
	}
	.accordion-item {
		margin: calc(100vw * 15/1024) 0;
	}  
	.accordion-title{
		font-size: calc(100vw * 24/1024);
		font-weight: 500;
	}  
	.accordion-content {
		font-size: calc(100vw * 24/1024);
	}
	.multi-list-holder {
		max-width: 100%;
	}
	.multi-list-item p {
		margin: calc(100vw * 3/1024);
		padding: calc(100vw * 10/1024) calc(100vw * 10/1024);
		font-size: calc(100vw * 16/1024);
		line-height: calc(100vw * 24/1024);
	}
	.multi-list-item p svg {
		font-size: calc(100vw * 12/1024);
		margin-right: calc(100vw * 15/1024);
	}
}

@media only screen and (max-width: 599px) {
	:root {
		--section-width-wider: calc(100vw * 550/390);
		--section-width-wide: calc(100vw * 550/390);
		--section-width-boxed: calc(100vw * 450/390);
	}
	.row {
		flex-direction: column;
	}
	.col {
		width: 100% !important;
	}
	.desktop {
		display: none !important;
	}
	.mobile {
		display: block;
	}
	.btn {	
		font-weight: 400;
		font-size: calc(100vw * 14/390);
		line-height: calc(100vw * 14/390);
		width: calc(100vw * 135/390);
		padding: calc(100vw * 12/390);
		text-align: center;
	}
	.btn-primary {	
		border: calc(100vw * 1/390) solid var(--color-blue-dark);
	}
	.btn-secondary {	
		border: calc(100vw * 1/390) solid var(--color-blue-light);
	}
	.accordion-item {
		margin: calc(100vw * 12/390) 0;
	}  
	.accordion-title{
		font-size: calc(100vw * 16/390);
		font-weight: 500;
	}  
	.accordion-content {
		font-size: calc(100vw * 16/390);
	}
	.multi-list-holder {
		max-width: 100%;
		margin: 0 auto;
	}
	.multi-list-item {
		width: 100%;
	}
	.multi-list-item p {
		margin: calc(100vw * 5/390);
		padding: calc(100vw * 10/390) calc(100vw * 20/390);
		font-size: calc(100vw * 20/390);
		line-height: calc(100vw * 23.4/390);
	}
	.multi-list-item p svg {
		font-size: calc(100vw * 18/390);
		margin-right: calc(100vw * 15/390);
	}
}
