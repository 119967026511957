/* Footer*/ 
.site-footer {
	max-width: none;
	margin: 0;
	margin-top: 0 !important;
	padding: calc(100vw * 55/1900) 0;
	background-color: var(--color-blue-light);
}
.site-footer .listing {
	padding-left: 0;
}
.site-footer .listing > li {
	list-style: none;
	line-height: 1;
	margin-bottom: calc(100vw * 35/1900);
}
.site-footer .listing > li > a,
.site-footer .listing > li > span {
	position: relative;
	color: var(--color-white);
	font-size: calc(100vw * 55/1900);
	font-weight: 700;
	line-height: calc(100vw * 71/1900);
}
.site-footer .listing > li > a:after {
	content: '';
	width: 0;
	height: calc(100vw * 1/1900);
	background-color: var(--color-white);
	position: absolute;
	bottom: 0;
	left: 0;
}
.site-footer .listing > li a:hover:after {
	width: 100%;
	transition: all 0.3s ease-in-out;
}
.site-footer .listing > li {
	margin-bottom: 0;
}
.site-footer .image-holder a {
    position: relative;
    display: block;
}
.site-footer .map-holder div:nth-child(2) {
	width: 100%;
	height: calc(100vw * 500/1900);
	position: relative;
	overflow: hidden;
}
.site-footer .image-holder img {
	width: 100%;
}
.site-footer .container > .row:last-child {
	margin-top: calc(100vw * 125/1900);
	align-items: center;
}
.site-footer .socials a {
	font-size: calc(100vw * 36/1900);
	margin-right: calc(100vw * 15/1900);
	display: inline-block;
	position: relative
}
.site-footer .socials a svg {
	color: var(--color-white);
}
.site-footer .socials a:after {
	content: '';
	width: 0;
	height: calc(100vw * 1/1900);
	bottom: calc(-1 * 100vw * 5/1900);
	left: 0;
	display: block;
	background: var(--color-white);
}
.site-footer .socials a:hover:after {
	width: 100%;
	transition: all 0.3s ease-in-out;
}
.site-footer .footer-notes {
	font-size:  calc(100vw * 18/1900);
	text-align: right;
	color: var(--color-white);
}
.site-footer .footer-notes .first {
	margin-right:  calc(100vw * 150/1900);
}
.site-footer .footer-notes a {
	font-weight: 600;
  color: var(--color-blue-dark);
}
.footer-notes > span,
.footer-notes > a {
	margin-left:  calc(100vw * 25/1900);
}
@media only screen and (max-width: 1024px) {
	.site-footer {
		padding: calc(100vw * 35/1024) 0;
	}
	.site-footer .listing > li {
		margin-bottom: calc(100vw * 10/1024);
	}
	.site-footer .listing > li > a,
	.site-footer .listing > li > span {
		font-size: calc(100vw * 32/1024);
		line-height: calc(100vw * 40/1024);
	}
	.site-footer .listing > li > a:after {
		height: calc(100vw * 1/1024);
	}
	.site-footer .map-holder div:nth-child(2) {
		height: calc(100vw * 500/1024);
	}
	.site-footer .container > .row:last-child {
		margin-top: calc(100vw * 55/1024);
	}
	.site-footer .socials a {
		font-size: calc(100vw * 28/1024);
		margin-right: calc(100vw * 15/1024);
	}
	.site-footer .socials a:after {
		height: calc(100vw * 1/1024);
		bottom: calc(-1 * 100vw * 5/1024);
	}
	.site-footer .footer-notes {
		font-size:  calc(100vw * 12/1024);
	}
	.site-footer .footer-notes .first {
		margin-right:  calc(100vw * 50/1024);
	}
	.footer-notes > span {
		margin-left:  calc(100vw * 15/1024);
	}
}

@media only screen and (max-width: 599px) {
	.site-footer {
		padding: calc(100vw * 55/390) calc(100vw * 35/390);
	}
	.site-footer .listing {
		margin-bottom: calc(100vw * 25/390);
	}
	.site-footer .listing > li {
		margin-bottom: calc(100vw * 5/390);
	}
	.site-footer .listing > li > a,
	.site-footer .listing > li > span {
		font-weight: 600;
		font-size: calc(100vw * 30/390);
		line-height: calc(100vw * 44/390);
	}
	.site-footer .listing > li > a:after {
		height: calc(100vw * 1/390);
	}
	.site-footer .map-holder > div:last-child {
		height: calc(100vw * 300/390) !important;
	}
	.site-footer .container > .row:last-child {
		margin-top: calc(100vw * 25/390);
	}
	.site-footer .socials a {
		font-size: calc(100vw * 28/390);
		margin-right: calc(100vw * 25/390);
		margin-bottom: calc(100vw * 35/390);
	}
	.site-footer .socials a:after {
		height: calc(100vw * 1/390);
		bottom: calc(-1 * 100vw * 5/390);
	}
	.site-footer .footer-notes {
		font-size:  calc(100vw * 18/390);
		text-align: left;
	}
	.site-footer .footer-notes .first {
		margin-right:  0;
	}
	.map-holder iframe {
    border: none;
    width: 100%;
    height: 100%;
}
	.footer-notes > span {
		margin-left:  0;
		margin-bottom: calc(100vw * 15/390);
		display: block;
	}
}
