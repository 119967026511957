#banner {
  margin-top: calc(100vw * 130/1900);
  padding: calc(100vw * 120/1900) 0;
}
/* #banner .background-holder {
  background-image: url('../img/imperial-pdg-banner-image.webp');
} */
#banner .content-holder {
  padding: calc(100vw * 55/1920) calc(100vw * 35/1900);
  background-color: rgba(255, 255, 255, 0.45);
  margin-bottom: calc(100vw * 55/1900);
}
.homepage #banner .content-holder {
  max-width: 70%;
}
.plumbingmaintenance .col-60 {
    width: 85%;
}
#banner .btn-holder {
  padding-left: calc(100vw * 35/1900);
}
#banner .title {
  font-size: calc(100vw * 72/1900);
  font-weight: 400;
  line-height: calc(100vw * 72/1900);
  color: var(--color-blue-dark);
  text-transform: uppercase;
  margin-bottom: calc(100vw * 15/1900);
}
#banner .subtitle {
  font-size: calc(100vw * 28/1900);
  font-weight: 400;
  line-height: calc(100vw * 28/1900);
}
#banner .desc {
  font-size: calc(100vw * 18/1900);
  font-weight: 400;
  line-height: calc(100vw * 28/1900);
  color: var(--color-dark);
}
#banner .btn {
  margin-right: calc(100vw * 15/1900);
}

#cta {
  position: relative;
}
#cta::before {
  content: "";
  display: block;
  width: 50%;
  height: 100%;
  background-color: var(--color-blue-light);
  position: absolute;
  top: 0;
  left: 0;
}
#cta::after {
  content: "";
  display: block;
  width: 50%;
  height: 100%;
  background-color: var(--color-blue-dark);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}
#cta .col {
  display: flex;
}
#cta .cta-holder {
  flex: 0 0 calc(100vw * 275/1900);
  position: relative;
  overflow: hidden;
}
#cta .cta-holder .cta {
  display: block;
  padding: calc(100vw * 25/1900) 0;
}
#cta .cta-holder .cta span {
  display: block;
}
#cta .cta-holder .cta .cta-text {
  font-weight: 600;
  font-size: calc(100vw * 32/1900);
  line-height: calc(100vw * 35/1900);
  color: var(--color-white);
}
#cta .cta-holder .cta .cta-text-sub {
  font-weight: 500;
  font-size: calc(100vw * 33/1900);
  line-height: calc(100vw * 35/1900);
  color: var(--color-white);
}
#cta .cta-holder .cta-shape {
  position: absolute;
  content: "";
  display: flex;
  width: calc(100vw * 300/1900);
  height: calc(100vw * 300/1900);
  background-color: var(--color-blue-dark);
  transform: rotate(45deg)skew(25deg, 25deg);
  top: calc(-1 * 100vw * 90/1900);
  right: calc(-1 * 100vw * 225/1900);
  z-index: 1;
}
#cta .cta-holder .cta-shape::after {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 100%;
  background: var(--color-blue-dark);
  z-index: -1;
}
#cta .cta-holder:hover .cta-shape::after {
  animation-name: ripple;
  animation-duration: 1.5s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
}
#cta .cta-options {
  background-color: var(--color-blue-dark);
  padding: calc(100vw * 25/1900) 0;
  flex: 0 0 100%;
}
#cta .cta-option {
  display: inline-flex;
  align-items: center;
  width: calc(100vw * 440/1900);
  margin-right: calc(100vw * 10/1900);
}
#cta .cta-option .option-img {
  flex: 0 0 calc(100vw * 74/1900);
  margin-right: calc(100vw * 15/1900);
  position: relative;
}
#cta .cta-option .option-img::before {
  position: absolute;
  border: calc(100vw * 1/1900) dashed var(--color-white);
  border-radius: 50%;
  display: block;
  top: 0;
  left: 0;
  content: "";
  width: calc(100vw * 74/1900);
  height: calc(100vw * 74/1900);
}
#cta .cta-option:hover .option-img::before {
  animation-name: rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}
#cta .cta-option .option-img img {
  width: 100%;
}
#cta .cta-option .option-details .option-title {
  font-weight: 600;
  font-size: calc(100vw * 22/1900);
  line-height: calc(100vw * 28/1900);
  margin-bottom: calc(100vw * 5/1900);
  color: var(--color-white);
}
#cta .cta-option .option-details .option-desc {
  font-weight: 500;
  font-size: calc(100vw * 16/1900);
  line-height: calc(100vw * 24/1900);
  color: var(--color-white);
}

.section-1 {
  padding: calc(100vw * 75/1900) 0 calc(100vw * 125/1900);
}
.section-1 .title {
  font-weight: 500;
  font-size: calc(100vw * 165/1900);
  line-height: calc(100vw * 193/1900);
  margin-bottom: calc(100vw * 75/1900);
  color: var(--color-blue-dark);
}
.section-1 .subtitle {
  font-weight: 600;
  font-size: calc(100vw * 50/1900);
  line-height: calc(100vw * 60/1900);
  padding-right: calc(100vw * 60/1900);
  color: var(--color-dark);
}
.section-1 .desc {
  font-weight: 400;
  font-size: calc(100vw * 40/1900);
  line-height: calc(100vw * 55/1900);
  color: var(--color-dark);
}

.section-2 .row {
  gap: calc(100vw * 10/1900);
  margin-bottom: calc(100vw * 10/1900);
}
.section-2 .flip-box {
  background-color: transparent;
  height: calc(100vw * 563/1900);
  perspective: 1000px;
}
.section-2 .flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
}
.section-2 .flip-box-front, 
.section-2 .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.section-2 .flip-box-front {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; 
  background-size: cover;
  background-position: center;
}
.section-2 .flip-box.hot-water-systems .flip-box-front .background-holder {
  background-image: url('../img/hot-water-systems.webp');
}
.section-2 .flip-box.bathroom-kitchen .flip-box-front .background-holder {
  background-image: url('../img/bathroom-kitchen.webp');
}
.section-2 .flip-box.water-leaks .flip-box-front .background-holder {
  background-image: url('../img/water-leaks.webp');
}
.section-2 .flip-box.contact-us .flip-box-front .background-holder {
  background-image: url('../img/contact-us.webp');
}
.section-2 .flip-box-front .title,
.section-2 .flip-box-back .title {
  font-weight: 500;
  font-size: calc(100vw * 37/1900);
  line-height: calc(100vw * 43/1900);
  text-align: center;
  text-transform: uppercase;
  color: var(--color-white);
  display: block;
}
.section-2 .flip-box-back {
  background-color: var(--color-blue-light);
  /* transform: rotateX(180deg); */
  display: none;
  visibility: hidden;
  opacity: 0;
  align-items: center; /* Vertically center the content */
  justify-content: center; 
  z-index: 2;
}
.section-2 .flip-box-inner:hover .flip-box-back {
  display: flex;
  visibility: visible;
  opacity: 1;
}
.section-2 .flip-box-back .title {
  margin-bottom: calc(100vw * 20/1900);
}
.section-2 .flip-box-back .desc {
  font-weight: 500;
  font-size: calc(100vw * 24/1900);
  line-height: calc(100vw * 28/1900);
  text-align: center;
  max-width: 75%;
  margin: 0 auto;
  color: var(--color-white);
}

.section-3 {
  padding: calc(100vw * 150/1900) 0 calc(100vw * 175/1900);
}
.section-3 h2.title {
  font-weight: 500;
  font-size: calc(100vw * 66/1900);
  line-height: calc(100vw * 70/1900);
  margin-bottom: calc(100vw * 75/1900);
  text-align: center;
}
.section-3 .row {
  gap: calc(100vw * 150/1900);
}
.section-3 .content-holder {
  margin-bottom: calc(100vw * 45/1900);
}
.section-3 .content-holder .title {
  font-weight: 700;
  font-size: calc(100vw * 27/1900);
  line-height: calc(100vw * 32/1900);
  color: var(--color-blue-light);
  padding-bottom: calc(100vw * 5/1900);
  margin-bottom: calc(100vw * 25/1900);
  position: relative;
  display: inline-block;
}
.section-3 .content-holder .title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vw * 4/1900);
  background-color: var(--color-blue-light);
}
.section-3 .content-holder .desc {
  font-weight: 400;
  font-size: calc(100vw * 24/1900);
  line-height: calc(100vw * 28/1900);
  color: var(--color-dark);
}

.section-4 {
  background-color: var(--color-blue-light);
  padding: calc(100vw * 55/1900) 0 0;
}
.section-4 .img-holder {
  position: relative;
  min-height: calc(100vw * 300/1900);
}
.section-4 .img-holder img {
    width: 100%;
    position: absolute;
    bottom: 0;
}
.section-4 .left .subtitle {
  font-weight: 400;
  font-size: calc(100vw * 28/1900);
  line-height: calc(100vw * 55/1900);
  color: var(--color-blue-dark);
}
.section-4 .left .title {
  font-weight: 500;
  font-size: calc(100vw * 52/1900);
  line-height: calc(100vw * 61/1900);
  color: var(--color-white);
}
.section-4 .right .desc {
  font-weight: 400;
  font-size: calc(100vw * 21/1900);
  line-height: calc(100vw * 37/1900);
  color: var(--color-white);
  margin-bottom: calc(100vw * 25/1900);
}
.section-4 .right .btn-holder {
  position: relative;
}
.section-4 .right a.call-btn {
  display: inline-flex;
  flex-direction: column;
  /* align-items: center; */
  padding-left: calc(100vw * 75/1900);
}
.section-4 .right a.call-btn .menu-text {
  min-height: auto;
}
.section-4 .right a.call-btn .menu-icon {
  /* display: inline-block; */
  width: calc(100vw * 50/1900);
  height: calc(100vw * 50/1900);
  border-radius: 50%;
  line-height: calc(100vw * 50/1900);
  text-align: center;
  left: 0;
  top: calc(50% - ((100vw * 50/1900)/2));
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-blue-light);
  border: calc(100vw * 5/1900) solid var(--color-blue-dark);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.section-4 .right a.call-btn .menu-icon .step-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-blue-light);
  border-radius: 50%;
}
.section-4 .right a.call-btn .menu-icon::after {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  height: 100%;
  width: 100%;
  background: var(--color-blue-dark);
  border-radius: 100%;
  z-index: -1;

  animation-name: ripple;
  animation-duration: 1.5s;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
}
.section-4 .right a.call-btn .menu-text {
  font-size: calc(100vw * 25/1900);
  font-weight: 600;
  line-height: calc(100vw * 18/1900); 
  text-align: left;
  flex: 1;
  color: var(--color-white);
}
.section-4 .right a.call-btn .menu-text-sub {
  font-size: calc(100vw * 12/1900);
  font-weight: 400;
  line-height: calc(100vw * 18/1900);     
  text-align: left;
  margin-bottom: calc(100vw * 5/1900);  
  flex: 1;
  align-items: end;
  display: flex;
  color: var(--color-white);
}

.section-5 {
  background-color: var(--color-blue-dark);
  padding: calc(100vw * 75/1900);
}
.section-5 .row {
  gap: calc(100vw * 55/1900);
  position: relative;
}
.section-5 .row .line {
  position: absolute;
  width: 100%;
  height: 0;
  display: block;
  border-top: calc(100vw * 1/1900) dashed var(--color-white);
  top: calc(100vw * 40/1900);
}
.section-5 .row .line::before,
.section-5 .row .line::after {
  content: "";
  position: absolute;
  display: block;
  width: calc(100vw * 20/1900);
  height: calc(100vw * 20/1900);
  border-radius: 50%;
  background-color: var(--color-blue-light);
  top: calc(-1 * 100vw * 10.5/1900);
}
.section-5 .row .line::before {
  left: 0;
}
.section-5 .row .line::after {
  right: 0;
}
.section-5 .subtitle {
  font-weight: 400;
  font-size: calc(100vw * 28/1900);
  line-height: calc(100vw * 55/1900);
  text-align: center;
  color: var(--color-blue-light);
}
.section-5 .title {
  font-weight: 500;
  font-size: calc(100vw * 66/1900);
  line-height: calc(100vw * 70/1900);
  text-align: center;
  color: var(--color-white);
  margin-bottom: calc(100vw * 35/1900);
}
.section-5 .desc {
  font-weight: 300;
  font-size: calc(100vw * 24/1900);
  line-height: calc(100vw * 28/1900);
  text-align: center;
  color: var(--color-white);
  max-width: calc(100vw * 1234/1900);
  margin: 0 auto calc(100vw * 75/1900);
}
.section-5 .content-holder .title {
  font-weight: 600;
  font-size: calc(100vw * 24/1900);
  line-height: calc(100vw * 28/1900);
  text-align: center;
  color: var(--color-white);
  margin-bottom: calc(100vw * 25/1900);
}
.section-5 .content-holder .desc {
  max-width: 100%;
  margin: 0;
}
.section-5 .content-holder .img-holder {
  background-color: var(--color-blue-light);
  width: calc(100vw * 80/1900);
  height: calc(100vw * 80/1900);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto calc(100vw * 75/1900);
  position: relative;
}
.section-5 .content-holder .img-holder::before {
  content: "";
  display: block;
  position: absolute;
  width: calc(100vw * 100/1900);
  height: calc(100vw * 100/1900);
  border: calc(100vw * 1/1900) dashed var(--color-white);
  border-radius: 50%;
  left: calc(-1 * 100vw * 11/1900);
  top: calc(-1 * 100vw * 11/1900);
}
.section-5 .content-holder:hover .img-holder::before {
  animation-name: rotate;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}
.section-5 .content-holder .img-holder img {
  max-width: calc(100vw * 35/1900);
}

.section-6 {
  padding: calc(100vw * 100/1900);
  background-color: var(--color-grey);
}
.section-6 .title {
  font-weight: 600;
  font-size: calc(100vw * 107/1900);
  line-height: calc(100vw * 105/1900);
  text-align: center;
  letter-spacing: -3%;
  margin-bottom: calc(100vw * 75/1900);
}
.section-6 .testimonial-desc {
  max-width: calc(100vw * 1100/1900);
  margin: 0 auto calc(100vw * 55/1900);
  font-weight: 400;
  font-size: calc(100vw * 24/1900);
  line-height: calc(100vw * 28/1900);
  text-align: center;
}
.section-6 .testimonial-name {
  font-weight: 500;
  font-size: calc(100vw * 50/1900);
  line-height: calc(100vw * 58/1900);
  text-align: center;
  margin-bottom: calc(100vw * 55/1900);
}

.section-9 .img-holder img,
.section-10 .img-holder img {
  width: 100%;
}
.section-9 .content-holder,
.section-10 .content-holder {
  padding: calc(100vw * 55/1900);
}
.section-9 .content-holder .title,
.section-10 .content-holder .title,
.section-11 .content-holder .title {
  font-weight: 600;
  font-size: calc(100vw * 66/1900);
  line-height: calc(100vw * 70/1900);
  letter-spacing: -1%;
  margin-bottom: calc(100vw * 25/1900);
}
.section-9 .content-holder .subtitle,
.section-10 .content-holder .subtitle,
.section-11 .content-holder .subtitle {
  font-weight: 400;
  font-size: calc(100vw * 28/1900);
  line-height: calc(100vw * 55/1900);
  color: #009ABF;
}
.section-9 .content-holder .desc,
.section-10 .content-holder .desc {
  font-weight: 400;
  font-size: calc(100vw * 24/1900);
  line-height: calc(100vw * 28/1900);
  margin-bottom: calc(100vw * 25/1900);
  max-width: 86%;
}
.section-9 .content-holder h6,
.section-10 .content-holder h6 {
  font-weight: 600;
  font-size: calc(100vw * 24/1900);
  line-height: calc(100vw * 28/1900);
  margin-bottom: calc(100vw * 25/1900);
  color: #009ABF;
  max-width: 86%;
}
.section-9 .content-holder > .desc,
.section-10 .content-holder > .desc,
.section-9 .content-holder > .desc .desc:last-child,
.section-10 .content-holder > .desc .desc:last-child {
  margin-bottom: 0;
}
.section-11 .content-holder {
  padding-top: calc(100vw * 55/1900);
  text-align: center;
}
.section-locations {
  padding: calc(100vw * 75/1900);
}
.section-locations .title {
  font-weight: 600;
  font-size: calc(100vw * 66/1900);
  line-height: calc(100vw * 70/1900);
  text-align: center;
  letter-spacing: -3%;
  margin-bottom: calc(100vw * 75/1900);
}
.map-holder iframe {
  border: none;
}

@media only screen and (max-width: 1024px) {
  #banner {
    padding: calc(100vw * 100/1024) 0;
  }
  #banner .left {
    width: 60%;
  }
  #banner .right {
    width: auto;
  }
  #banner .title {
    font-size: calc(100vw * 54/1024);
    line-height: calc(100vw * 54/1024);
    margin-bottom: calc(100vw * 15/1024);
  }
  #banner .subtitle {
    font-size: calc(100vw * 28/1024);
    line-height: calc(100vw * 28/1024);
  }
  #banner .desc {
    font-size: calc(100vw * 16/1024);
    line-height: calc(100vw * 20/1024);
    margin-bottom: calc(100vw * 75/1024);
  }
  #banner .btn {
    margin-right: calc(100vw * 15/1024);
  }  
  #cta .cta-holder {
    flex: 0 0 calc(100vw * 275/1024);
  }
  #cta .cta-holder .cta {
    padding: calc(100vw * 15/1024) 0;
  }
  #cta .cta-holder .cta .cta-text {
    font-size: calc(100vw * 32/1024);
    line-height: calc(100vw * 36/1024);
  }
  #cta .cta-holder .cta .cta-text-sub {
    font-size: calc(100vw * 24/1024);
    line-height: calc(100vw * 28/1024);
  }
  #cta .cta-holder .cta-shape {
    width: calc(100vw * 300/1024);
    height: calc(100vw * 300/1024);
    top: calc(-1 * 100vw * 100/1024);
    right: calc(-1 * 100vw * 225/1024);
  }
  #cta .cta-options {
    padding: calc(100vw * 25/1024) 0;
  }
  #cta .cta-option {
    width: calc(100vw * 300/1024);
    margin-right: calc(100vw * 10/1024);
  }
  #cta .cta-option .option-img {
    flex: 0 0 calc(100vw * 50/1024);
    margin-right: calc(100vw * 15/1024);
  }
  #cta .cta-option .option-img::before {
    border: calc(100vw * 1/1024) dashed var(--color-white);
    width: calc(100vw * 50/1024);
    height: calc(100vw * 50/1024);
  }
  #cta .cta-option .option-details .option-title {
    font-size: calc(100vw * 16/1024);
    line-height: calc(100vw * 20/1024);
    margin-bottom: calc(100vw * 5/1024);
  }
  #cta .cta-option .option-details .option-desc {
    font-size: calc(100vw * 12/1024);
    line-height: calc(100vw * 16/1024);
  }  
  .section-1 {
    padding: calc(100vw * 75/1024) 0 calc(100vw * 125/1024);
  }
  .section-1 .title {
    font-size: calc(100vw * 88/1024);
    line-height: calc(100vw * 96/1024);
    margin-bottom: calc(100vw * 35/1024);
  }
  .section-1 .subtitle {
    font-size: calc(100vw * 36/1024);
    line-height: calc(100vw * 40/1024);
    padding-right: calc(100vw * 60/1024);
  }
  .section-1 .desc {
    font-size: calc(100vw * 28/1024);
    line-height: calc(100vw * 32/1024);
  }  
  .section-2 .row {
    gap: calc(100vw * 5/1024);
    margin-bottom: calc(100vw * 5/1024);
  }
  .section-2 .flip-box {
    height: calc(100vw * 400/1024);
  }
  .section-2 .flip-box-front .title,
  .section-2 .flip-box-back .title {
    font-size: calc(100vw * 32/1024);
    line-height: calc(100vw * 36/1024);
  }
  .section-2 .flip-box-back .title {
    margin-bottom: calc(100vw * 20/1024);
  }
  .section-2 .flip-box-back .desc {
    font-size: calc(100vw * 16/1024);
    line-height: calc(100vw * 24/1024);
  }  
  .section-3 {
    padding: calc(100vw * 75/1024) 0 calc(100vw * 75/1024);
  }
  .section-3 h2.title {
    font-size: calc(100vw * 64/1024);
    line-height: calc(100vw * 72/1024);
    margin-bottom: calc(100vw * 55/1024);
  }
  .section-3 .row {
    gap: calc(100vw * 150/1024);
  }
  .section-3 .content-holder {
    margin-bottom: calc(100vw * 45/1024);
  }
  .section-3 .content-holder .title {
    font-size: calc(100vw * 24/1024);
    line-height: calc(100vw * 32/1024);
    padding-bottom: calc(100vw * 5/1024);
    margin-bottom: calc(100vw * 25/1024);
  }
  .section-3 .content-holder .title::after {
    height: calc(100vw * 2/1024);
  }
  .section-3 .content-holder .desc {
    font-size: calc(100vw * 16/1024);
    line-height: calc(100vw * 24/1024);
  }
  .section-4 {
    padding: calc(100vw * 25/1024) 0 0;
  }
  .section-4 .img-holder {
    min-height: calc(100vw * 175/1024);
  }
  .section-4 .left .subtitle {
    font-size: calc(100vw * 16/1024);
    line-height: calc(100vw * 24/1024);
  }
  .section-4 .left .title {
    font-size: calc(100vw * 28/1024);
    line-height: calc(100vw * 32/1024);
  }
  .section-4 .right .desc {
    font-size: calc(100vw * 16/1024);
    line-height: calc(100vw * 20/1024);
    margin-bottom: calc(100vw * 15/1024);
  }
  .section-4 .right a.call-btn {
    padding-left: calc(100vw * 55/1024);
  }
  .section-4 .right a.call-btn .menu-icon {
    width: calc(100vw * 40/1024);
    height: calc(100vw * 40/1024);
    line-height: calc(100vw * 40/1024);
    top: calc(50% - ((100vw * 40/1024)/2));
    border: calc(100vw * 5/1024) solid var(--color-blue-dark);
  }
  .section-4 .right a.call-btn .menu-text {
    font-size: calc(100vw * 24/1024);
    line-height: calc(100vw * 18/1024); 
  }
  .section-4 .right a.call-btn .menu-text-sub {
    font-size: calc(100vw * 12/1024);
    line-height: calc(100vw * 18/1024);    
    margin-bottom: calc(100vw * 5/1024);  
  }  
  .section-5 {
    padding: calc(100vw * 35/1024);
  }
  .section-5 .row {
    gap: calc(100vw * 25/1024);
  }
  .section-5 .row .line {
    border-top: calc(100vw * 1/1024) dashed var(--color-white);
    top: calc(100vw * 40/1024);
  }
  .section-5 .row .line::before,
  .section-5 .row .line::after {
    width: calc(100vw * 20/1024);
    height: calc(100vw * 20/1024);
    top: calc(-1 * 100vw * 10.5/1024);
  }
  .section-5 .subtitle {
    font-size: calc(100vw * 24/1024);
    line-height: calc(100vw * 32/1024);
  }
  .section-5 .title {
    font-size: calc(100vw * 52/1024);
    line-height: calc(100vw * 60/1024);
    margin-bottom: calc(100vw * 35/1024);
  }
  .section-5 .desc {
    font-size: calc(100vw * 16/1024);
    line-height: calc(100vw * 20/1024);
    max-width: 100%;
    margin: 0 auto calc(100vw * 55/1024);
  }
  .section-5 .content-holder .title {
    font-size: calc(100vw * 16/1024);
    line-height: calc(100vw * 20/1024);
    margin-bottom: calc(100vw * 15/1024);
  }
  .section-5 .content-holder .img-holder {
    width: calc(100vw * 80/1024);
    height: calc(100vw * 80/1024);
    margin: 0 auto calc(100vw * 35/1024);
  }
  .section-5 .content-holder .img-holder::before {
    width: calc(100vw * 100/1024);
    height: calc(100vw * 100/1024);
    border: calc(100vw * 1/1024) dashed var(--color-white);
    left: calc(-1 * 100vw * 11/1024);
    top: calc(-1 * 100vw * 11/1024);
  }
  .section-5 .content-holder .img-holder img {
    max-width: calc(100vw * 35/1024);
  }  
  .section-6 {
    padding: calc(100vw * 55/1024);
  }
  .section-6 .title {
    font-size: calc(100vw * 72/1024);
    line-height: calc(100vw * 80/1024);
    margin-bottom: calc(100vw * 55/1024);
  }
  .section-6 .testimonial-desc {
    max-width: 100%;
    margin: 0 auto calc(100vw * 55/1024);
    font-size: calc(100vw * 24/1024);
    line-height: calc(100vw * 32/1024);
  }
  .section-6 .testimonial-name {
    font-size: calc(100vw * 36/1024);
    line-height: calc(100vw * 44/1024);
    margin-bottom: calc(100vw * 55/1024);
  }
  .section-9 .content-holder,
  .section-10 .content-holder {
    padding: calc(100vw * 55/1024);
  }
  .section-9 .content-holder .title,
  .section-10 .content-holder .title,
  .section-11 .content-holder .title {
    font-size: calc(100vw * 66/1024);
    line-height: calc(100vw * 70/1024);
    margin-bottom: calc(100vw * 25/1024);
  }
  .section-9 .content-holder .subtitle,
  .section-10 .content-holder .subtitle,
  .section-11 .content-holder .subtitle {
    font-size: calc(100vw * 28/1024);
    line-height: calc(100vw * 55/1024);
  }
  .section-9 .content-holder .desc,
  .section-10 .content-holder .desc {
    font-size: calc(100vw * 24/1024);
    line-height: calc(100vw * 28/1024);
    margin-bottom: calc(100vw * 25/1024);
  }
  .section-9 .content-holder h6,
  .section-10 .content-holder h6 {
    font-size: calc(100vw * 24/1024);
    line-height: calc(100vw * 28/1024);
    margin-bottom: calc(100vw * 25/1024);
  }
  .section-locations {
    padding: calc(100vw * 75/1024);
  }
  .section-locations .title {
    font-size: calc(100vw * 44/1024);
    line-height: calc(100vw * 52/1024);
    margin-bottom: calc(100vw * 75/1024);
  }
  
  
}


@media only screen and (max-width: 599px) {
  #banner {
    margin-top: calc(100vw * 60/390);
    padding: calc(100vw * 125/390) calc(100vw * 15/390) calc(100vw * 55/390);
  }
  .homepage #banner .background-holder {
    background-image: url(../img/imperial-pdg-banner-image-mobile.webp);
  }
  .hotwatersystems #banner .background-holder,
  .waterfilterinstallation #banner .background-holder {
    background-position: 63%;
  }
  #banner .content-holder,
  #banner .btn-holder {
    padding: calc(100vw * 15/390);
    max-width: 60%;
  }
  #banner .title {
    font-size: calc(100vw * 26/390);
    line-height: calc(100vw * 32/390);
    margin-bottom: calc(100vw * 15/390);
  }
  #banner .subtitle {
    font-size: calc(100vw * 18/390);
    line-height: calc(100vw * 18/390);
  }
  #banner .desc {
    font-weight: 500;
    font-size: calc(100vw * 14/390);
    line-height: calc(100vw * 20/390);
    margin-bottom: calc(100vw * 0/390);
  }
  #banner .btn {
    margin-bottom: calc(100vw * 10/390);
  }  
  #cta {
    background-color: var(--color-blue-dark);
    padding: calc(100vw * 10/390) 0 0;
  }
  #cta::before,
  #cta::after {
    display: none;
  }
  #cta .col {
    flex-direction: column;
  }
  #cta .cta-holder {
    flex-basis: auto;
  }
  #cta .cta-holder .cta {
    padding: calc(100vw * 15/390) 0;
    display: flex;
  }
  #cta .cta-holder .cta .cta-text-sub {
    font-size: calc(100vw * 14/390);
    line-height: calc(100vw * 17.92/390);
    margin-left: calc(100vw * 85/390);
  }
  #cta .cta-holder .cta .cta-text {
    font-size: calc(100vw * 14/390);
    line-height: calc(100vw * 17.92/390);
    margin-left: calc(100vw * 55/390);
  }
  #cta .cta-holder .cta-shape {
    display: none;
  }
  #cta .cta-options {
    padding: calc(100vw * 5/390) 0;
  }
  #cta .cta-option {
    width: 100%;
    margin-bottom: calc(100vw * 25/390);
    margin-right: calc(100vw * 35/390);
    padding: 0 calc(100vw * 25/390);
  }
  #cta .cta-option .option-img {
    flex: 0 0 calc(100vw * 48/390);
    margin-right: calc(100vw * 10/390);
  }
  #cta .cta-option .option-img::before {
    border: calc(100vw * 1/390) dashed var(--color-white);
    width: calc(100vw * 48/390);
    height: calc(100vw * 48/390);
  }
  #cta .cta-option .option-details .option-title {
    font-size: calc(100vw * 18/390);
    line-height: calc(100vw * 23.04/390);
    margin-bottom: calc(100vw * 15/390);
  }
  #cta .cta-option .option-details .option-desc {
    font-size: calc(100vw * 14/390);
    line-height: calc(100vw * 16.41/390);
    padding-right: calc(100vw * 55/390);
  }  
  .section-1 {
    padding: calc(100vw * 55/390) calc(100vw * 40/390);
  }
  .section-1 .title {
    font-weight: 600;
    font-size: calc(100vw * 51/390);
    line-height: calc(100vw * 56/390);
    margin-bottom: calc(100vw * 25/390);
  }
  .section-1 .subtitle {
    font-size: calc(100vw * 26/390);
    line-height: calc(100vw * 30.42/390);
    padding-right: calc(100vw * 50/390);
    margin-bottom: calc(100vw * 25/390);
  }
  .section-1 .desc {
    font-size: calc(100vw * 18/390);
    line-height: calc(100vw * 24/390);
  }  
  .section-2 .row {
    gap: calc(100vw * 5/390);
    margin-bottom: calc(100vw * 5/390);
  }
  .section-2 .flip-box {
    height: calc(100vw * 232.1/390);
  }
  .section-2 .flip-box-front .title,
  .section-2 .flip-box-back .title {
    font-size: calc(100vw * 16/390);
    font-weight: 600;
    line-height: calc(100vw * 32/390);
  }
  .section-2 .flip-box-back .title {
    margin-bottom: calc(100vw * 16/390);
  }
  .section-2 .flip-box-back .desc {
    font-size: calc(100vw * 12/390);
    line-height: calc(100vw * 16/390);
  }  
  .section-3 {
    padding: calc(100vw * 55/390) calc(100vw * 30/390);
  }
  .section-3 .boxed {
    max-width: 100%;
  }
  .section-3 .img-holder {
    margin-bottom: calc(100vw * 35/390);
  }
  .section-3 h2.title {
    font-weight: 600;
    font-size: calc(100vw * 32/390);
    line-height: calc(100vw * 37.44/390);
    margin-bottom: calc(100vw * 35/390);
    text-align: left;
  }
  .section-3 .row {
    gap: 0;
  }
  .section-3 .content-holder {
    margin-bottom: calc(100vw * 35/390);
  }
  .section-3 .content-holder .title {
    font-size: calc(100vw * 18/390);
    line-height: calc(100vw * 21.06/390);
    padding-bottom: calc(100vw * 5/390);
    margin-bottom: calc(100vw * 25/390);
  }
  .section-3 .content-holder .title::after {
    height: calc(100vw * 2/390);
  }
  .section-3 .content-holder .desc {
    font-size: calc(100vw * 16/390);
    font-weight: 400;
    line-height: calc(100vw * 18.72/390);
    max-width: 95%;
  }
  .section-4 {
    padding: calc(100vw * 55/390) calc(100vw * 30/390);
  }
  .section-4 .left .subtitle {
    font-size: calc(100vw * 18/390);
    line-height: calc(100vw * 21.06/390);
    margin-bottom: calc(100vw * 15/390);
  }
  .section-4 .left .title {
    font-size: calc(100vw * 32/390);
    line-height: calc(100vw * 37.44/390);
    letter-spacing: -0.03em;
    margin-bottom: calc(100vw * 15/390);
  }
  .section-4 .right .desc {
    font-size: calc(100vw * 16/390);
    line-height: calc(100vw * 24/390);
    margin-bottom: calc(100vw * 55/390);
  }
  .section-4 .right a.call-btn {
    padding-left: calc(100vw * 85/390);
  }
  .section-4 .right a.call-btn .menu-icon {
    width: calc(100vw * 52/390);
    height: calc(100vw * 52/390);
    line-height: calc(100vw * 52/390);
    top: calc(35% - ((100vw * 52/390)/2));
    border: calc(100vw * 5/390) solid var(--color-blue-dark);
  }
  .section-4 .right a.call-btn .menu-icon .step-inner {
    font-size: calc(100vw * 18/390);
  }
  .section-4 .right a.call-btn .menu-text {
    font-size: calc(100vw * 25/390);
    line-height: calc(100vw * 18/390); 
  }
  .section-4 .right a.call-btn .menu-text-sub {
    font-size: calc(100vw * 12/390);
    line-height: calc(100vw * 18/390);    
    margin-bottom: calc(100vw * 5/390);  
  }  
  .section-5 {
    padding: calc(100vw * 55/390) calc(100vw * 15/390) calc(100vw * 15/390);
  }
  .section-5 .row {
    gap: calc(100vw * 25/390);
  }
  .section-5 .subtitle {
    font-size: calc(100vw * 18/390);
    line-height: calc(100vw * 21.06/390);
  }
  .section-5 .title {
    font-size: calc(100vw * 32/390);
    line-height: calc(100vw * 37.44/390);
    margin-bottom: calc(100vw * 15/390);
  }
  .section-5 .desc {
    font-size: calc(100vw * 16/390);
    line-height: calc(100vw * 22/390);
    max-width: 96%;
    margin: 0 auto calc(100vw * 35/390);
  }
  .section-5 .content-holder {
    margin-bottom: calc(100vw * 35/390);
  }
  .section-5 .content-holder .title {
    font-size: calc(100vw * 18/390);
    line-height: calc(100vw * 21.06/390);
    margin-bottom: calc(100vw * 10/390);
  }
  .section-5 .content-holder .img-holder {
    width: calc(100vw * 56/390);
    height: calc(100vw * 56/390);
    margin: 0 auto calc(100vw * 35/390);
  }
  .section-5 .content-holder .img-holder::before {
    width: calc(100vw * 80/390);
    height: calc(100vw * 80/390);
    border: calc(100vw * 1/390) dashed var(--color-white);
    left: calc(-1 * 100vw * 13/390);
    top: calc(-1 * 100vw * 13/390);
  }
  .section-5 .content-holder .img-holder img {
    max-width: calc(100vw * 35/390);
  }  
  .section-6 {
    padding: calc(100vw * 55/390);
  }
  .section-6 .title {
    font-size: calc(100vw * 32/390);
    line-height: calc(100vw * 37.44/390);
    margin-bottom: calc(100vw * 55/390);
  }
  .section-6 .testimonial-desc {
    max-width: 100%;
    margin: 0 auto calc(100vw * 55/390);
    font-size: calc(100vw * 16/390);
    line-height: calc(100vw * 24/390);
  }
  .section-6 .testimonial-name {
    font-size: calc(100vw * 32/390);
    font-weight: 600;
    line-height: calc(100vw * 37.44/390);
    margin-bottom: calc(100vw * 75/390);
  }
  .section-9 .container,
  .section-10 .container,
  .accordion {
    padding: calc(100vw * 25/390);
  }
  .section-10 .row {
    flex-direction: column-reverse;
  }
  .section-9 .content-holder,
  .section-10 .content-holder {
    padding: calc(100vw * 25/390) 0;
  }
  .section-9 .content-holder .title,
  .section-10 .content-holder .title,
  .section-11 .content-holder .title {
    font-size: calc(100vw * 48/390);
    line-height: calc(100vw * 52/390);
    margin-bottom: calc(100vw * 25/390);
  }
  .section-9 .content-holder .subtitle,
  .section-10 .content-holder .subtitle,
  .section-11 .content-holder .subtitle {
    font-size: calc(100vw * 16/390);
    line-height: calc(100vw * 24/390);
  }
  .section-9 .content-holder .desc,
  .section-10 .content-holder .desc {
    font-size: calc(100vw * 16/390);
    line-height: calc(100vw * 24/390);
    margin-bottom: calc(100vw * 25/390);
    max-width: 100%;
  }
  .section-9 .content-holder h6,
  .section-10 .content-holder h6 {
    font-size: calc(100vw * 20/390);
    line-height: calc(100vw * 24/390);
    margin-bottom: calc(100vw * 15/390);
  }
  .section-9 .img-holder,
  .section-10 .img-holder {
    max-height: 100vw;
    overflow: hidden;
  }
  .section-9 .desc img,
  .section-10 .desc img {
    height: calc(100vw * 40/390);
    width: auto;
    margin-bottom: calc(100vw * 10/390);
  }
  .section-locations {
    padding: calc(100vw * 55/390);
  }
  .section-locations .title {
    font-size: calc(100vw * 32/390);
    line-height: calc(100vw * 37.44/390);
    margin-bottom: calc(100vw * 75/390);
  }
  .hotwatersystems .section-9 .img-holder img,
  .hotwatersystems .section-10 .img-holder img {
    margin-top: -50vw;
  }
  .hotwatersystems > section:nth-child(5) > div > div > div:nth-child(2) > div > picture > img {
    margin-top: -30vw;
  }
  .hotwatersystems > section:nth-child(6) > div > div > div:nth-child(1) > div > picture > img {
    margin-top: -10vw;
  }
  .hotwatersystems > section:nth-child(7) > div > div > div:nth-child(2) > div {
    display: none;
  }
  .hotwatersystems > section:nth-child(7) > div > div > div:nth-child(1) > div > div > img:nth-child(5) {
    height: calc(100vw * 80/390);
  }
  .waterfilterinstallation .section-9 .desc img, 
  .waterfilterinstallation .section-10 .desc img {
    height: calc(100vw * 250/390);
  }
  .waterfilterinstallation > section:nth-child(5) > div > div > div:nth-child(2) > div > picture > img {
    margin-top: -30vw;
  }
  .waterfilterinstallation > section:nth-child(6) > div > div > div:nth-child(1) > div > picture > img {
    margin-top: -10vw;
  }
  .waterfilterinstallation > section:nth-child(7) > div > div > div:nth-child(2) > div {
    display: none;
  }
}